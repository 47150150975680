<template>
  <div class="content">
    <v-card
      :style="
        $vuetify.breakpoint.xs
          ? 'margin: 0 0 0 0'
          : 'margin: 0 18px 0 18px'"
    >
      <base-loading v-if="loading" />
      <div v-else>
        <crud-list
          v-model="ofertas"
          :headers="headers"
          :slots="[
            'item.status',
            'item.data_fim',
            'item.data_inicio',
            'item.unidades_negocio'
          ]"
          :actions="actions"
          :permission="'list_oferta'"
          :sort-by="['nome']"
          :sort-desc="[false]"
          export-pdf-xlsx
        >
          <template
            v-if="hasPermission('inserir_oferta')"
            v-slot:btnCadastro
          >
            <v-col>
              <v-btn
                class="text-none text-white"
                block
                color="primary"
                dark
                rounded
                @click="NEW_OFERTA"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-file-plus
                </v-icon>
                Cadastrar
              </v-btn>
            </v-col>
            <oferta-dialog />
          </template>
          <template
            v-if="hasPermission('ativar_oferta')"
            v-slot:[`item.status`]="{ item }"
          >
            <v-switch
              v-model="item.status"
              dense
              inset
              color="success"
              :loading="false"
              hint="Ativar/Desativar a promoção!"
              :disabled="isDisabled(item)"
              @change="TOGGLE_OFERTA_STATUS(item)"
            />
          </template>
          <template v-slot:[`item.unidades_negocio`]="{ item }">
            <div class="multiline-cell">
              {{ item.unidades_negocio }}
            </div>
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            <span>{{ formatData(item.data_fim) }}</span>
          </template>
          <template v-slot:[`item.data_inicio`]="{ item }">
            <span>{{ formatData(item.data_inicio) }}</span>
          </template>
        </crud-list>
      </div>
    </v-card>
  </div>
</template>
<script>
  import store from '@/store'
  import OfertaStore, { CLOSE } from '@/store/modules/forms/oferta'
  import OfertasStore, { BOOTSTRAP, CHANGE_STATUS, EDIT_OFERTA, NEW_OFERTA, DELETE_OFERTA, TOGGLE_OFERTA_STATUS } from '@/store/modules/ofertas'
  import OfertaDialog from './OfertaDialog.vue'
  import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'

  export default {
    components: {
      OfertaDialog,
    },
    data () {
      return {
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '80' },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            groupable: false,
            floatingfilter: false,
            align: 'start',
            width: '80',
            report: true,
            styleXLSX: { type: 'alterValue' },
            alterValue: { 0: 'inativo', 1: 'ativo' },
          },
          {
            text: 'Data Início',
            value: 'data_inicio',
            sortable: true,
            groupable: true,
            floatingfilter: false,
            report: true,
            styleReport: { halign: 'center' },
            styleXLSX: { type: 'date' },
          },
          {
            text: 'Data Fim',
            value: 'data_fim',
            sortable: true,
            groupable: true,
            floatingfilter: false,
            report: true,
            styleReport: { halign: 'center' },
            styleXLSX: { type: 'date' },
          },
          { text: 'Descrição', value: 'nome', sortable: true, groupable: false, floatingfilter: true, report: true },
          { text: 'Tipo', value: 'tipo', sortable: false, groupable: true, floatingfilter: true, report: true },
          { text: 'Entrega (dias)', value: 'entrega', width: '30', sortable: true, groupable: false, floatingfilter: false, report: true },
          { text: 'Unidades de negócio', width: '320', value: 'unidades_negocio', sortable: true, groupable: false, floatingfilter: false, report: true },
        ],
        actions: [
          {
            title: 'Editar a Promoção',
            color: 'info darken-3',
            click: item => this.EDIT_OFERTA(item),
            icon: 'mdi-pencil',
            permission: 'editar_oferta',
          },
          {
            title: 'Excluir a Promoção',
            color: 'red darken-3',
            click: item => this.DELETE_OFERTA(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_oferta',
          },
        ].filter(({ permission }) => store.getters.hasPermission(permission)),
      }
    },
    computed: {
      ...mapState('ofertas', ['loading', 'ofertas']),
      ...mapState('form/oferta', ['dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.state.form.oferta) { this.$store.registerModule(['form', 'oferta'], OfertaStore) }
      if (!this.$store.state.ofertas) { this.$store.registerModule('ofertas', OfertasStore) }
      this.BOOTSTRAP()
      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))
    },
    methods: {
      ...mapActions('ofertas', [BOOTSTRAP, EDIT_OFERTA, NEW_OFERTA, DELETE_OFERTA, TOGGLE_OFERTA_STATUS]),
      ...mapMutations('ofertas', [CHANGE_STATUS]),
      ...mapMutations('form/oferta', [CLOSE]),
      formatData (date) {
        if (!date) {
          return ''
        }
        const data = new Date(date)
        data.setTime(data.getTime() + data.getTimezoneOffset() * 60 * 1000)
        return (this.adicionaZero(data.getDate()) + '/' + this.adicionaZero(data.getMonth() + 1) + '/' + data.getFullYear())
      },
      adicionaZero (numero) {
        if (numero <= 9) {
          return '0' + numero
        } else {
          return numero
        }
      },
      getDataValue (date) {
        const newDate = new Date()
        if (date) {
          const dateArray = date.substring(0, 10).split('-')
          newDate.setFullYear(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]))
        }
        newDate.setHours(0, 0, 0, 0)
        return newDate.valueOf()
      },
      isDisabled (oferta) {
        // VERIFICAR A QUESTÃO DA DATA NOS PRIMEIROS DIAS DO MÊS (DANDO IGUAL O FINAL)
        const dataAtual = this.getDataValue()
        const dataInicioOferta = this.getDataValue(oferta.data_inicio)
        const dataFinalOferta = this.getDataValue(oferta.data_fim)
        if (dataInicioOferta <= dataAtual && dataAtual <= dataFinalOferta) {
          return false
        } else {
          if (dataAtual > dataFinalOferta) {
            this.CHANGE_STATUS({ oferta })
          }
          return true
        }
      },
      handleFilterInput (item) {
        item.checked = item.value || (item.value && item.checked)
      },
    },
  }
</script>
<style>
  .multiline-cell {
    white-space: pre-wrap; /* Respeita as quebras de linha dentro do conteúdo */
  }
</style>
