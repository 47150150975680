import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import Swal from 'sweetalert2'
import { Oferta } from '@/models/Oferta'
import { Regra } from '@/models/Regra'
import rules from '@/utils/formRules'

export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const SET_STATE = 'SET_STATE'
export const LOAD_PRODUTOS = 'LOAD_PRODUTOS'
export const LOAD_UNIDADES_NEGOCIO_GESTOR = 'LOAD_UNIDADES_NEGOCIO_GESTOR'
export const BACK = 'BACK'
export const NEXT = 'NEXT'
export const CLOSE = 'CLOSE'
export const NEW_REGRA = 'NEW_REGRA'
export const REMOVE_REGRA = 'REMOVE_REGRA'
export const CLEAR_REGRAS = 'CLEAR_REGRAS'
export const CLEAR_COMDICOES = 'CLEAR_COMDICOES'
export const NEW_CONDICAO = 'NEW_CONDICAO'
export const REMOVE_CONDICAO = 'REMOVE_CONDICAO'
export const ADD_PRODUTOS = 'ADD_PRODUTOS'
export const REMOVE_PRODUTO = 'REMOVE_PRODUTO'
export const RESET_STATE = 'RESET_STATE'
export const NOTIFICA_OFERTA = 'NOTIFICA_OFERTA'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'

const getDefaultState = () => {
  return {
    dialog: false,
    loadedUnidades: [],
    unidadesMedida: [],
    produtos: [],
    produtosEscolhidos: [],
    isEditing: false,
    loading: true,
    novoProduto: false,
    step: 1,
    rules,
  }
}

const getDataValue = (date) => {
  const newDate = new Date()
  if (date) {
    const dateArray = date.substring(0, 10).split('-')
    newDate.setFullYear(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]))
  }
  newDate.setHours(0, 0, 0, 0)
  return newDate.valueOf()
}

const state = getDefaultState()

const getters = {
  getField,
  submitted: () => { return state.step === 2 },
  produtosEmOferta: (state, getters, rootState) => {
    const dataAtual = getDataValue()
    const ofertas_ativas = rootState.ofertas.ofertas.filter(oferta => oferta.id !== rootState.form.data.id && (getDataValue(oferta.data_inicio) <= dataAtual && dataAtual <= getDataValue(oferta.data_fim) && oferta.status === (1 || true)))
    const produtos_em_ofertas = ofertas_ativas.map(oferta => oferta.regras.map(regra => regra.produtos.map(produto => (typeof produto === 'object') ? produto.id : produto))).flat(2)
    return produtos_em_ofertas
  },
  produtoDisponivel: (state, getters, rootState) => {
    const dataAtual = getDataValue()
    const ofertas_ativas = rootState.ofertas.ofertas.filter(oferta => oferta.id !== rootState.form.data.id && (getDataValue(oferta.data_inicio) <= dataAtual && dataAtual <= getDataValue(oferta.data_fim) && oferta.status === (1 || true)))
    const produtos_em_ofertas = ofertas_ativas.map(oferta => {
      return { unidades: oferta.unidades.map(unidade => unidade.id), produtos: oferta.regras.map(regra => regra.produtos.map(produto => (typeof produto === 'object') ? produto.id : produto)).flat() }
    })
    return produtos_em_ofertas
  },
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [BACK] (state) {
    state.step--
  },
  [CLOSE] (state) {
    state.step = 1
    state.dialog = false
    state.form = new Oferta()
  },

  [REMOVE_CONDICAO] (state, { regra, condicao }) {
    const index = regra.condicoes.indexOf(condicao)
    if (index >= 0) regra.condicoes.splice(index, 1)
  },
  [ADD_PRODUTOS] (state, regra) {
    state.produtosEscolhidos.forEach(element => {
      element.regra = regra.id
      regra.produtos.push(element)
    })
    state.produtosEscolhidos = []
    state.novoProduto = true
  },
  [REMOVE_PRODUTO] (state, { regra, item }) {
    const index = regra.produtos.indexOf(item)
    if (index >= 0) regra.produtos.splice(index, 1)
  },
}

const actions = {
  [CLEAR_COMDICOES] ({ rootState }, { indexRegra }) {
    rootState.form.data.regras[indexRegra].clearCondicoes()
  },
  [NEW_CONDICAO] ({ rootState, dispatch }, { indexRegra }) {
    rootState.form.data.regras[indexRegra].addCondicao()
  },
  [NEW_REGRA] ({ rootState, dispatch }) {
    rootState.form.data.regras.unshift(new Regra())
  },
  [REMOVE_REGRA] ({ rootState }, regra) {
    rootState.form.data.removeRegra(regra)
  },
  [CLEAR_REGRAS] ({ rootState }) {
    rootState.form.data.clearRegras()
  },
  [NEXT] ({ commit, dispatch, state, getters }) {
    commit('SET_STATE', { step: (state.step + 1) })
    dispatch(LOAD_PRODUTOS)
  },
  async [LOAD_UNIDADES_NEGOCIO_GESTOR] ({ commit, rootState }) {
    commit(SET_STATE, { loadedUnidades: await api.listEntidade(`${rootState.user.roles[0].slug}/unidadesNegocio`) })
  },
  async [LOAD_PRODUTOS] ({ commit, rootState }) {
    const unidades = rootState.form.data.unidades.map(unidade => unidade.id)

    const produtos = (await api.getProdutosUnidades({ unidades })).filter(p => p.disponibilidade !== 2)
    commit(SET_STATE, { produtos })
  },
  async [LOAD_UNIDADES] ({ commit }) {
    commit(SET_STATE, { unidadesMedida: await api.listEntidade('unidade') })
  },
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false, dialog: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new Oferta() }, { root: true })
    await dispatch(LOAD_UNIDADES_NEGOCIO_GESTOR)
    await dispatch(LOAD_UNIDADES)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      api.getEntidade('oferta', id).then(response => {
        dispatch('form/BOOTSTRAP_FORM', { model: new Oferta(response.data[0]) }, { root: true })
      })
    }
    commit(SET_STATE, { loading: false })
  },
  async [NOTIFICA_OFERTA] ({ dispatch, rootState }) {
    const dados = { from: rootState.form.data.unidades.map(unidade => unidade.id), tipo: 2, title: '', mensagem: '' }

    api.cadastrarEntidade(dados, 'notificacao/save_notificacao').catch(err => {
      console.error(err)
      window.Toast.fire('Não foi possível enviar a mensagem da oferta!', '', 'error')
    })
  },
  async [SUBMIT] ({ dispatch, commit, state, getters, rootState }) {
    const form = { ...rootState.form.data }
    const oferta = rootState.form.data
    const produtoDisponivel = getters.produtoDisponivel
    const dataAtual = (new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + (new Date()).getDate()
    let countProdutos = 0

    const produtosValidos = (produto) => {
      const unidades = form.unidades.map(und => und.id)
      const retorno = produtoDisponivel.filter((elemento) => {
        const retornoUnidade = elemento.unidades.filter(und => unidades.includes(und))
        const retornoProduto = elemento.produtos.includes(produto)
        return (retornoUnidade.length > 0 && retornoProduto)
      })
      return (retorno.length <= 0)
    }

    oferta.regras.forEach(regra => {
      regra.produtos = regra.produtos.filter(produto => (produtosValidos((typeof produto === 'object') ? produto.id : produto)))
      countProdutos += regra.produtos.length
    })

    if (countProdutos > 0) {
      form.unidades = rootState.form.data.unidades.map(unidade => unidade.id)
      form.regras = rootState.form.data.regras.map(({ produtos, ...rest }) => ({
        produtos: produtos.map(produto => produto.id),
        ...rest,
      }))

      form.data_inicio = form.data_inicio.substr(0, 10)
      form.data_fim = form.data_fim.substr(0, 10)
      form.status = 1

      if (form.tipo === 'oferta' && state.novoProduto && dataAtual >= form.data_inicio && dataAtual <= form.data_fim) {
        await dispatch(NOTIFICA_OFERTA)
      }

      if (!state.isEditing) {
        Swal.fire({
          title: `Cadastrando a ${form.tipo}!`,
        })
        Swal.showLoading()
        api.cadastrarEntidade(form, 'oferta').then(response => {
          dispatch('ofertas/LOAD_OFERTAS', null, { root: true })
          commit('CLOSE')
          Swal.hideLoading()
          Swal.update({
            icon: 'success',
            title: `A ${form.tipo} foi cadastrada com sucesso!`,
          })
        }).catch(err => {
          console.error(err)
          Swal.hideLoading()
          let errorMessage = `Não foi possível cadastrar a ${form.tipo}, favor tentar novamente.`
          if (err.code && err.code === 400) {
            errorMessage = err.error
            dispatch('ofertas/LOAD_OFERTAS', null, { root: true })
            commit('CLOSE')
          }
          Swal.update({
            icon: 'error',
            title: errorMessage,
          })
        })
      } else {
        Swal.fire({
          title: `Editando a ${form.tipo}!`,
        })
        Swal.showLoading()
        api.salvarEntidade(form, 'oferta', form.id).then(response => {
          dispatch('ofertas/LOAD_OFERTAS', null, { root: true })
          commit('CLOSE')
          Swal.hideLoading()
          Swal.update({
            icon: 'success',
            title: `A ${form.tipo} foi editada com sucesso!`,
          })
        }).catch(err => {
          console.error(err)
          Swal.hideLoading()
          Swal.update({
            icon: 'error',
            title: `Não foi possível editar a ${form.tipo}, favor tentar novamente.`,
          })
        })
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        html: '<b>Não restaram Produtos nas regras desta Promoção</b>!<br>Portanto acesse a listagem de produtos e faça as correções necessárias, para poder salvá-la.',
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
