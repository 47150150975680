var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('v-card',{style:(_vm.$vuetify.breakpoint.xs
        ? 'margin: 0 0 0 0'
        : 'margin: 0 18px 0 18px')},[(_vm.loading)?_c('base-loading'):_c('div',[_c('crud-list',{attrs:{"headers":_vm.headers,"slots":[
          'item.status',
          'item.data_fim',
          'item.data_inicio',
          'item.unidades_negocio'
        ],"actions":_vm.actions,"permission":'list_oferta',"sort-by":['nome'],"sort-desc":[false],"export-pdf-xlsx":""},scopedSlots:_vm._u([(_vm.hasPermission('inserir_oferta'))?{key:"btnCadastro",fn:function(){return [_c('v-col',[_c('v-btn',{staticClass:"text-none text-white",attrs:{"block":"","color":"primary","dark":"","rounded":""},on:{"click":_vm.NEW_OFERTA}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-file-plus ")]),_vm._v(" Cadastrar ")],1)],1),_c('oferta-dialog')]},proxy:true}:null,(_vm.hasPermission('ativar_oferta'))?{key:`item.status`,fn:function({ item }){return [_c('v-switch',{attrs:{"dense":"","inset":"","color":"success","loading":false,"hint":"Ativar/Desativar a promoção!","disabled":_vm.isDisabled(item)},on:{"change":function($event){return _vm.TOGGLE_OFERTA_STATUS(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}:null,{key:`item.unidades_negocio`,fn:function({ item }){return [_c('div',{staticClass:"multiline-cell"},[_vm._v(" "+_vm._s(item.unidades_negocio)+" ")])]}},{key:`item.data_fim`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatData(item.data_fim)))])]}},{key:`item.data_inicio`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatData(item.data_inicio)))])]}}],null,true),model:{value:(_vm.ofertas),callback:function ($$v) {_vm.ofertas=$$v},expression:"ofertas"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }